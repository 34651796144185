import React, { useLayoutEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Admin() {
    useLayoutEffect(() => (window.location.href = "https://fsco.admin.datocms.com/admin"), [])
    return (
        <Layout>
            <div className='text-center'>
                <p className='mt40'>Please wait for redirect...</p>
            </div>
        </Layout>
    )
}

export const Head = () => <Seo title='Admin' />
